import React from 'react';
import { Link } from 'gatsby';

const LinkCardElement = ({ slug, url, children }) => {
  if (slug) {
    return (
      <Link to={slug} itemProp="url">
        {children}
      </Link>
      )
  }

  return (
    <a href={url} target="_blank" rel="noreferrer" >
      { children }
    </a>
  )
}


const CardElement = ({ title, subtitle, image, slug, url, date }) => (
  <LinkCardElement slug={slug} url={url}>
    <article className="card-element">
        <img
            src={image}
            alt={title + ' - Featured image'}
            className="featured-image"
          />
        <p className="card-title">
            {title} <br/>
            {subtitle}
        </p>
      { date && <p className="card-date">{date}</p> }
    </article>
  </LinkCardElement>
);

export default CardElement;
