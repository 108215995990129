import React from 'react';
import { Link } from 'gatsby';
import { RiArrowRightLine, RiArrowLeftLine } from 'react-icons/ri';

const Pagination = ({ context, slug }) => {

    const { currentPage, numPages } = context ;
    const isFirstElement = currentPage === 1;
    const isLastElemnt = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? slug : slug + '/' + (currentPage - 1).toString();
    const nextPage = slug + '/' + (currentPage + 1).toString();

    return (
        <div className="pagination">
            <ul>
                {!isFirstElement && (
                <li>
                    <Link to={prevPage} rel="prev">
                        <span className="icon -left"><RiArrowLeftLine/></span> Previous
                    </Link>
                </li>
                )}
                {Array.from({ length: numPages }, (_, i) => (
                    <li key={`pagination-number${i + 1}`} >
                        <Link
                        to={`${slug}/${i === 0 ? '' : i + 1}`}
                        className={currentPage === i + 1 ? "is-active num" : "num"}
                        >
                            {i + 1}
                        </Link>
                    </li>
                ))}
                {!isLastElemnt && (
                <li>
                    <Link to={nextPage} rel="next">
                        Next <span className="icon -right"><RiArrowRightLine/></span>
                    </Link>
                </li>
                )}
            </ul>
        </div>
    )
};

export default Pagination;